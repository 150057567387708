import ChallengeContext from '@cohort/wallet/components/contexts/ChallengeContext';
import NotFoundPage from '@cohort/wallet/components/error-pages/NotFoundPage';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import {useStandaloneChallenge} from '@cohort/wallet/hooks/api/Campaign';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useParams} from 'react-router-dom';

interface ChallengeContextProviderProps {
  children: React.ReactNode;
}

const ChallengeContextProvider = ({children}: ChallengeContextProviderProps): JSX.Element => {
  const merchant = useMerchantContext();
  const {challengeSlug} = useParams();

  const {data: challenge, isError} = useStandaloneChallenge(
    merchant.id,
    challengeSlug ?? '',
    challengeSlug !== undefined
  );

  if (isError) {
    return <NotFoundPage />;
  }

  if (challenge === undefined || challengeSlug === undefined) {
    return <LoadingScreen />;
  }

  return <ChallengeContext.Provider value={challenge}>{children}</ChallengeContext.Provider>;
};

export default ChallengeContextProvider;
