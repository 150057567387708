/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {CampaignsApi} from '@cohort/wallet/lib/Endpoints';

export const campaignsKeys = {
  campaigns: ['campaigns'] as const,
  getStoreBySlug: (merchantId: string, campaignSlug: string, redeemCode?: string) =>
    [...campaignsKeys.campaigns, merchantId, campaignSlug, redeemCode] as const,
  getChallengeBySlug: (merchantId: string, campaignSlug: string) =>
    [...campaignsKeys.campaigns, merchantId, 'challenge', campaignSlug] as const,
  getStandaloneChallengeBySlug: (merchantId: string, challengeSlug: string) =>
    [...campaignsKeys.campaigns, merchantId, 'standalone-challenge', challengeSlug] as const,
};

export const useStoreBySlug = (
  merchantId: string,
  campaignSlug: string,
  redeemCode?: string,
  enabled?: boolean
) => {
  const getCampaign = useApi(CampaignsApi.getStoreBySlug);

  return useCohortQuery({
    queryKey: campaignsKeys.getStoreBySlug(merchantId, campaignSlug, redeemCode),
    queryFn: async () => getCampaign(merchantId, campaignSlug, redeemCode),
    enabled,
  });
};

export const useChallengeCampaign = (
  merchantId: string,
  campaignSlug: string,
  enabled?: boolean
) => {
  const getCampaign = useApi(CampaignsApi.getChallengeBySlug);

  return useCohortQuery({
    queryKey: campaignsKeys.getChallengeBySlug(merchantId, campaignSlug),
    queryFn: async () => getCampaign(merchantId, campaignSlug),
    enabled,
  });
};

export const useStandaloneChallenge = (
  merchantId: string,
  challengeSlug: string,
  enabled?: boolean
) => {
  const getStandaloneChallenge = useApi(CampaignsApi.getStandaloneChallengeBySlug);

  return useCohortQuery({
    queryKey: campaignsKeys.getStandaloneChallengeBySlug(merchantId, challengeSlug),
    queryFn: async () => getStandaloneChallenge(merchantId, challengeSlug),
    enabled,
  });
};
