import Title from '@cohort/components-xps/components/Title';
import type {
  TypeformTakeQuizTriggerStruct,
  TypeformTakeQuizVerificationAttemptInput,
} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';
import {useChallengeCampaign} from '@cohort/wallet/hooks/api/Campaign';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {Widget} from '@typeform/embed-react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

const TypeformTakeQuizUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<TypeformTakeQuizTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config}) => {
  const {stepId, challengeSlug} = useParams();
  const merchant = useMerchantContext();
  const user = useUserStore(store => store.user);
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.take-quiz',
  });

  const {data: campaign, isFetched: isCampaignFetched} = useChallengeCampaign(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    challengeSlug!,
    challengeSlug !== undefined
  );

  if (!user || (isCampaignFetched && campaign === undefined)) {
    return <NotFoundSection />;
  } else if (campaign === undefined) {
    return <Fragment />;
  }

  const step = campaign.challenge.steps.find(step => step.id === stepId);
  if (step === undefined) {
    throw new Error(`No step found for id: ${stepId}`);
  }

  return (
    <div className="space-y-7">
      <Title content={t('title', {score: config.minScore})} className="text-center md:text-left" />
      <Widget
        id={config.formId}
        inlineOnMobile
        height={550}
        style={{width: '100%'}}
        className="md:min-w-[340px] lg:min-w-[400px]"
        onSubmit={data => {
          const verificationInput: TypeformTakeQuizVerificationAttemptInput = data;
          onTriggerIntegrationUsageSuccess(verificationInput);
        }}
        hidden={{
          cohort_user_id: user.id,
        }}
      />
    </div>
  );
};

export default TypeformTakeQuizUsageComponent;
